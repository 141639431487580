<template>
  <div class="xzj">
    <van-button type="info" square @click="$router.go(-1)" style="width: 100%"
      >返回</van-button
    >
    <div id="modelShow" class="model"></div>
  </div>
</template>

<script>
import { GetModelInfos, GetPlanDetailOne } from "@/api";
export default {
  name: "Xzj",
  components: {},
  data() {
    return {
      modelUrl: [],
      viewer: null,
      modelId: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 查看选择集
    async orientation() {
      let params = {
        modelId: this.modelId,
      };
      const res = await GetModelInfos(params);
      res.Data.forEach((v) => {
        this.modelUrl.push(this.$store.state.baseUrl + v.mode09);
      });
      this.showModel();
    },
    showModel() {
      var _this = this;
      var config = {
        extensions: ["Autodesk.Viewing.ZoomWindow"],
        disabledExtensions: {
          measure: false,
          section: false,
        },
        memory: {
          limit: 32 * 1024, //32 GB
        },
        theme: "light-theme",
      };

      var element = document.getElementById("modelShow");
      _this.viewer = new Autodesk.Viewing.Private.GuiViewer3D(element, config);
      var options = {
        env: "Local",
        offline: "true",
        useADP: false,
      };

      const modelOpts = {
        applyRefPoint: true,
        globalOffset: {
          x: 0,
          y: 0,
          z: 0,
        },
      };

      Autodesk.Viewing.Initializer(options, function () {
        _this.viewer.start();
        _this.modelUrl.forEach((url) => {
          _this.viewer.loadModel(url, modelOpts);
        });
        let i = 0;
        _this.viewer.addEventListener(
          Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
          function () {
            i++;
            if (i == _this.modelUrl.length) {
              i = 0;
              _this.$Toast("模型加载完成");
              _this.viewer.impl
                .modelQueue()
                .getModels()
                .forEach((model) => {
                  _this.viewer.hide([model.getRootId()], model);
                    if (model.loader.svfUrn.indexOf(_this.$route.params.url) > 0) {
                  _this.viewer.show(_this.$route.params.mosl05.split(",").map(Number), model);
                    }
                });
            }
          }
        );
      });
    },
  },
  created() {},
  mounted() {
    if (this.$route.params.modelId) {
      this.modelId = this.$route.params.modelId;
      console.log(this.modelId);
      this.orientation();
    }
  },
};
</script>
<style lang='scss' scoped>
.xzj {
  width: 100%;
  height: 100%;
  #modelShow {
    height: 100%;
    width: 100%;
    position: absolute;
  }
}
</style>